import { Seo } from '@types';

const businessName = 'Raja Kuppuswamy';

export const seo: Seo = [
  {
    description:
      'Expertise in Shelter, CCCM Camp Construction and WASH project management for internally displaced people, refugees and Governments.',
    page: '/',
    title: 'Raja Kuppuswamy | Emergency Response Consultant',
  },
  {
    description: 'Description for Achievements.',
    // TODO
    page: '/achievements',
    title: 'Achievements | Raja Kuppuswamy',
  },
  {
    description: 'Description for Publication.',
    // TODO
    page: '/publications',
    title: 'Publications | Raja Kuppuswamy',
  },
  {
    description: 'Description for My Work.',
    // TODO
    page: '/work',
    title: 'My Work | Raja Kuppuswamy',
  },
  {
    description: 'Description for Testimonials.',
    // TODO
    page: '/testimonials',
    title: 'Testimonials | Raja Kuppuswamy',
  },
  {
    description: 'Description for Gallery.',
    // TODO
    page: '/gallery',
    title: 'Gallery | Raja Kuppuswamy',
  },
  {
    description: 'Contact details and contact form.',
    page: '/contact',
    title: 'Contact | Raja Kuppuswamy',
  },
  {
    description: 'Not Found',
    page: '/404',
    title: '404 | Raja Kuppuswamy',
  },
  {
    description: `${businessName}'s Sitemap`,
    page: '/sitemap',
    title: 'Sitemap | Raja Kuppuswamy',
  },
  {
    description: `${businessName}'s Cookie Policy`,
    page: '/cookie-policy',
    title: 'Cookie Policy | Raja Kuppuswamy',
  },
  {
    description: `${businessName}'s Privacy Policy`,
    page: '/privacy-policy',
    title: 'Privacy Policy | Raja Kuppuswamy',
  },
  {
    description: `${businessName}'s Terms of Use`,
    page: '/terms-of-use',
    title: 'Terms of Use | Raja Kuppuswamy',
  },
];
