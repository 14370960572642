import { Sitedata } from '@types';

export const sitedata: Sitedata = {
  businessName: 'Raja Kuppuswamy - Emergency Response Consultant',
  contactName: 'Raja Kuppuswamy',

  cookieLifeInDays: 1,
  locations: {
    location1: {
      address: {
        addressLocality: 'Banting',
        addressRegion: 'Selangor',
        country: 'Malaysia',
        countryIso: 'MY',
        postalCode: '42700',
        streetAddress1: 'No.5, Jalan Harmoni 15',
        streetAddress2: 'Taman Harmoni, Morib',
      },
      ageRestriction: 100,
      emails: ['rajakuppuswamy2003@gmail.com', 'drrfrontliner@gmail.com'],
      googleMap: 'https://goo.gl/maps/ieCyDhxU79kYDNCK8',
      googleMapEmbed:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.285939326068!2d101.45162991582933!3d2.731274798010264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cda20d9fb1c509%3A0xf94a38c84ee06bd5!2s5%2C%20Jalan%20Harmoni%2015%2C%20Taman%20Harmoni%2C%2042700%20Banting%2C%20Selangor!5e0!3m2!1sen!2smy!4v1630555837785!5m2!1sen!2smy',
      latitude: 2.731499847597919,
      longitude: 101.45385078484357,
      openingHoursSpecification: [
        {
          closes: '17:00:00',
          dayOfWeek: 'Monday',
          opens: '08:00:00',
        },
        {
          closes: '17:00:00',
          dayOfWeek: 'Tuesday',
          opens: '08:00:00',
        },
        {
          closes: '17:00:00',
          dayOfWeek: 'Wednesday',
          opens: '08:00:00',
        },
        {
          closes: '17:00:00',
          dayOfWeek: 'Thursday',
          opens: '08:00:00',
        },
        {
          closes: '17:00:00',
          dayOfWeek: 'Friday',
          opens: '08:00:00',
        },
      ],
      phones: [
        {
          countryCode: 60,
          telephone: 125921608,
        },
      ],
      priceRange: 5,
    },
  },

  // uen: '0000000000',
  logo: 'logo.jpg',

  // eCommercePlatform: ['Shopify'],
  marketingPlatform: ['Facebook', 'Instagram', 'Twitter'],
  serviceType: 'Consultant',

  siteDescription: 'Raja Kuppuswamy - Emergency Response Consultant.',

  siteName: 'Raja Kuppuswamy',

  siteUrl: 'https://rajakuppuswamy.com',
  socialMedia: {
    facebookProfileId: '1437793687',
    linkedInHandle: 'rajasegarankuppusamy',
    twitterHandle: 'EngrRaja_',
    // facebookHandle: '',
    // instagramHandle: '',
    // fbAppId: '',
  },
};
