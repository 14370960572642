import { getSEO, PageSEO } from '@seo';
import React from 'react';

interface Props {
  location: {
    pathname: string;
  };
}

const Sitemap = ({ location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-sitemap.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <h1>Sitemap</h1>
    </>
  );
};

export default Sitemap;
